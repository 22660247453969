<template>
  <div>
    <modal className="w-11/12 sm:w-400px rounded" ref="updateIndustryModal">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Update {{ industry.name }}
        </h1>
        <div class="mt-10">
          <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(form) }}</span>
            </div>
          </template>

          <form-group name="name" :form="form" v-model="form.data.name.value">
            Industry
          </form-group>

          <dropzone
            class="mb-0 mr-2 w-full"
            v-model="form.data.image.value"
            accept=".png,.jpeg,.jpg"
          />
          <div class="mt-5 flex items-center">
            <input type="checkbox" :id="form.data.featured.name" v-model="form.data.featured.value">
            <label :for="form.data.featured.name" class="text-sm ml-1">Add As Featured Category</label>
          </div>
          <div class="mt-5">
            <button
              type="button"
              class="btn btn-blue w-full"
              @click.prevent="updateIndustry"
              :disabled="form.loading"
            >
              <sm-loader class="sm-loader-white" v-if="form.loading" />
              <span class="text-sm" v-else>Submit</span>
            </button>
          </div>

          <div
            class="form-group-2-text text-red-500 mb-2"
            v-if="getFirstError('image', form)"
          >
            {{ getFirstError("image", form) }}
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  props: {
    industry: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: "name", rules: "required|aplpha" },
        { name: "image", value: null, rules: "nullable" },
        { name: "featured", rules: "nullable" },
      ]),
    };
  },
  mounted() {
    this.$refs.updateIndustryModal.open();
    this.mapData();
  },
  methods: {
    mapData() {
      this.form.data.name.value = this.industry?.name;
      this.form.data.featured.value = this.industry?.featured
    },
    async updateIndustry() {
      this.form.loading = true

      const image = this.form.data.image.value === null 
      ? '' : this.form.data.image.value
        
      const data = new FormData();
      data.append("name", this.form.data.name.value);
      data.append("image", image);
      data.append("industry_id", this.industry?.id)
      data.append("featured", this.form.data.featured.value ? 1 : 0)

      await this.sendRequest(
        'admin.merchants.updateCategory',
        {
          data: data,
          success: () => {
            this.$parent.getIndustries()
            this.$refs.updateIndustryModal.close()
          },
          error: (error) => this.form.error = error,
        }
      );
      this.form.loading = false
    },
  },
};
</script>
